import { Routes as ReactRoutes, Route, useNavigate } from 'react-router-dom'
import loadable from '../components/organisms/Common/loader/loadable'
import Page404 from '../components/pages/page404'
import PrivateRoute from './PrivateRoute'
import ProtectedRoute from './ProtectedRoute'
import { useGetAuthUser } from '../queries/user'
import { useEffect } from 'react'
import {
  PRODUCT_EDITOR_GET_PERM,
  PRODUCT_EDITOR_SALES_GET_PERM,
  PRODUCT_EDITOR_SALES_UPDATE_PERM,
  PRODUCT_EDITOR_WAREHOUSE_GET_PERM,
  PRODUCT_EDITOR_WAREHOUSE_UPDATE_PERM,
  PRODUCT_EDITOR_MARKETING_GET_PERM,
  PRODUCT_EDITOR_MARKETING_UPDATE_PERM,
  PRODUCT_EDITOR_PRO_LINE_GET_PERM,
  PRODUCT_EDITOR_PRO_LINE_UPDATE_PERM,
  PRODUCT_EDITOR_RETAIL_GET_PERM,
  PRODUCT_EDITOR_RETAIL_UPDATE_PERM,
  PRODUCT_EDITOR_PURCHASING_GET_PERM,
  PRODUCT_EDITOR_PURCHASING_UPDATE_PERM,
  WMS_INVENTORY_TAKER_GET_PERM,
  PRODUCT_LISTS_GET_PERM,
  SHIPPING_UPDATE_PERM,
} from '../constants/iam'

import ProductsPricingTabs from '../components/pages/products/products-pricing/tabs'

export const HIDE_NAV_PATHS = [
  '/login',
  '/login/reset-password',
  '/login/reset-password-method',
  '/login/reset-password/verification',
  '/login/reset-password/submit-new-password',
  '/settings/staff-accounts/:staff_id',
]

const Page401 = loadable(() => import('../components/pages/page401'))
const Login = loadable(() => import('../components/pages/login/login'))
const ResetPassword = loadable(
  () => import('../components/pages/login/reset-password'),
)
const Verification = loadable(
  () => import('../components/pages/login/verification'),
)
const SubmitNewPassword = loadable(
  () => import('../components/pages/login/submit-new-password'),
)
const ResetPasswordMethod = loadable(
  () => import('../components/pages/login/reset-password-method'),
)

const Permissions = loadable(
  () => import('../components/pages/iam/permissions'),
)
const Permission = loadable(() => import('../components/pages/iam/permission'))
const Roles = loadable(() => import('../components/pages/iam/roles'))
const Role = loadable(() => import('../components/pages/iam/role'))
const RolePermissions = loadable(
  () => import('../components/pages/iam/role-permissions'),
)
const Users = loadable(() => import('../components/pages/iam/users'))
const UserRoles = loadable(() => import('../components/pages/iam/user-roles'))

const UserProfiles = loadable(
  () => import('../components/pages/iam/user-profiles'),
)

const UserProfile = loadable(
  () => import('../components/pages/iam/user-profile'),
)
const UserProfileRoles = loadable(
  () => import('../components/pages/iam/user-profile-roles'),
)

const BcInventoryPush = loadable(
  () => import('../components/pages/dealer-network/inventory-push'),
)

const Customers = loadable(
  () => import('../components/pages/customer/customers'),
)
const WmsInventoryTaker = loadable(
  () => import('../components/pages/inventory/wms-inventory-taker'),
)
const WmsInventoryTakerLogs = loadable(
  () => import('../components/pages/inventory/wms-inventory-taker-logs'),
)
const Tasks = loadable(() => import('../components/pages/customer/tasks'))
const Task = loadable(() => import('../components/pages/customer/task'))
const CustomerTasks = loadable(
  () => import('../components/pages/customer/customer-tasks'),
)
const CustomerTask = loadable(
  () => import('../components/pages/customer/customer-task'),
)
const TasksEmbedded = loadable(
  () => import('../components/pages/customer/customer-tasks-embedded'),
)
const TaskEmbedded = loadable(
  () => import('../components/pages/customer/customer-task-embedded'),
)
const ChangePassword = loadable(
  () => import('../components/pages/account/change-password'),
)
const Orders = loadable(() => import('../components/pages/orders'))
const Order = loadable(() => import('../components/pages/order'))
const ProductsPricingLanding = loadable(
  () => import('../components/pages/products/products-pricing/list'),
)
const ProductsDeals = loadable(
  () => import('../components/pages/products/products-deals'),
)
const ProductsDeal = loadable(
  () => import('../components/pages/products/products-deal'),
)
const CustomAssortments = loadable(
  () => import('../components/pages/products/custom-assortments'),
)
const CustomAssortment = loadable(
  () => import('../components/pages/products/custom-assortment'),
)
const CouponCodesUsed = loadable(
  () => import('../components/pages/products/coupon-codes-used'),
)
const ManageBannersTiles = loadable(
  () => import('../components/pages/settings/manage-banners-and-tiles'),
)
const StaffAccounts = loadable(
  () => import('../components/pages/settings/staff-accounts'),
)
const UserSettings = loadable(
  () => import('../components/pages/settings/user-settings'),
)
const Products = loadable(() => import('../components/pages/products/products'))
const ProductsLegacy = loadable(
  () => import('../components/pages/products/products-legacy'),
)
const OriginalProductEditor = loadable(
  () =>
    import('../components/pages/products/products-editor/tabs/pro-line-tab'),
)
const ProductEditorTabs = loadable(
  () => import('../components/pages/products/products-editor/'),
)
const StaffAccount = loadable(
  () => import('../components/pages/settings/staff-account'),
)

const SpiritQuoteMonthsSettings = loadable(
  () => import('../components/pages/fulfillment/spirit-quote/months-settings'),
)

const PickupDateTimesSettings = loadable(
  () =>
    import('../components/pages/fulfillment/pickup-date-times/months-settings'),
)

const ShippingOrderList = loadable(
  () => import('../components/pages/fulfillment/shipping/order-list'),
)

const ShippingOrderDetail = loadable(
  () => import('../components/pages/fulfillment/shipping/order-detail'),
)

const WarehouseInventory = loadable(
  () => import('../components/pages/inventory/InventoryPage'),
)

const SpiritQuoteMonthSettings = loadable(
  () => import('../components/pages/fulfillment/spirit-quote/month-settings'),
)

const SpiritQuoteMileageDiscounts = loadable(
  () =>
    import('../components/pages/fulfillment/spirit-quote/mileage-discounts'),
)

const SpiritQuotePalletDiscounts = loadable(
  () => import('../components/pages/fulfillment/spirit-quote/pallet-discounts'),
)

const CrudUiTabs = loadable(
  () => import('../components/pages/pricing/crud-ui-tabs'),
)

const PriceInboundFreight = loadable(
  () =>
    import(
      '../components/pages/pricing/price-inbound-freight/price-inbound-freight-detail'
    ),
)

const PriceSuppliers = loadable(
  () => import('../components/pages/pricing/price-suppliers/detail'),
)

const PriceProductsMultiplierSettings = loadable(
  () =>
    import(
      '../components/pages/pricing/price-product-multiplier/price-products-multiplier-settings'
    ),
)

const PriceProductMultiplierSettings = loadable(
  () =>
    import(
      '../components/pages/pricing/price-product-multiplier/price-product-multiplier-settings'
    ),
)

const PriceForex = loadable(
  () => import('../components/pages/pricing/price-forex/list'),
)

const PriceForexDetail = loadable(
  () => import('../components/pages/pricing/price-forex/detail'),
)

const PriceInboundTemplateItems = loadable(
  () => import('../components/pages/pricing/price-inbound-template-items/list'),
)

const PriceInboundTemplateItemsDetail = loadable(
  () =>
    import('../components/pages/pricing/price-inbound-template-items/detail'),
)

const ScrollToTop = loadable(
  () => import('../components/organisms/Common/ScrollToTop'),
)
const Landing = loadable(() => import('../components/pages'))

const InvoiceExport = loadable(
  () => import('../components/pages/accounting/invoice/export'),
)

const OrderPallets = loadable(
  () => import('../components/pages/warehouse/order-pallets'),
)

const PriceManufacturerDetail = loadable(
  () => import('../components/pages/pricing/price-manufacturers/detail'),
)

const PricingVersions = loadable(
  () => import('../components/pages/pricing/versions'),
)

const VersionSummary = loadable(
  () => import('../components/pages/pricing/summary'),
)

const BasketOfGoodsDetail = loadable(
  () => import('../components/pages/pricing/summary/basket-of-goods/detail'),
)

const ProductLists = loadable(
  () => import('../components/pages/products/product-lists/list'),
)

const ProductListDetail = loadable(
  () => import('../components/pages/products/product-lists/detail'),
)

const SpiritQuoteDateRange = loadable(
  () => import('../components/pages/fulfillment/spirit-quote/date-range'),
)

const Routes: React.FC = () => {
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetAuthUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (userData === undefined || !userData) {
      navigate('/login')
    }
  }, [])

  return (
    <div className="body-size">
      <ScrollToTop />
      <ReactRoutes>
        <Route path="/not-authorized" element={<Page401 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/reset-password" element={<ResetPassword />} />
        <Route
          path="/login/reset-password-method"
          element={<ResetPasswordMethod />}
        />
        <Route
          path="/login/reset-password/verification"
          element={<Verification />}
        />
        <Route
          path="/login/reset-password/submit-new-password"
          element={<SubmitNewPassword />}
        />
        <Route
          path="accounting/products-pricing/:products_id/:tab"
          element={<ProductsPricingTabs />}
        />
        <Route
          path="accounting/products-pricing/:products_id"
          element={<ProductsPricingTabs />}
        />
        <Route
          path="accounting/products-pricing"
          element={<ProductsPricingLanding />}
        />
        <Route
          path="accounting/product-pricing-projection-versions/:version_id/:tab"
          element={<CrudUiTabs />}
        />
        <Route
          path="accounting/product-pricing-projection-versions/:version_id"
          element={<VersionSummary />}
        />
        <Route
          path="accounting/product-pricing-projection-versions/:version_id/basket/:basket_of_goods_id"
          element={<BasketOfGoodsDetail />}
        />
        <Route
          path="accounting/product-pricing-projection-versions"
          element={<PricingVersions />}
        />
        <Route path="/accounting/products/deals" element={<ProductsDeals />} />
        <Route path="/accounting/products/deal" element={<ProductsDeal />} />
        <Route
          path="/accounting/products/deal/:deal_id"
          element={<ProductsDeal />}
        />
        <Route path="/accounting/invoice/export" element={<InvoiceExport />} />
        <Route
          path="/products/custom-assortments"
          element={<CustomAssortments />}
        />
        <Route
          path="/products/custom-assortment/:case_id/:version"
          element={<CustomAssortment />}
        />
        <Route
          path="/products/custom-assortment"
          element={<CustomAssortment />}
        />
        <Route
          path="products/coupon-codes-used"
          element={<CouponCodesUsed />}
        />
        <Route
          path={'/settings/manage-banners-and-tiles'}
          element={<ManageBannersTiles />}
        />
        <Route
          path={'/settings/product-lists'}
          element={
            <ProtectedRoute permissions={[PRODUCT_LISTS_GET_PERM]}>
              {<ProductLists />}
            </ProtectedRoute>
          }
        />
        <Route
          path={'/settings/product-lists/:id'}
          element={<ProductListDetail />}
        />

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Landing />} />
          <Route
            path={'/iam/permissions'}
            element={
              <ProtectedRoute permissions={['spark.iam.get']}>
                {<Permissions />}
              </ProtectedRoute>
            }
          />
          <Route path="/iam/permissions/:id" element={<Permission />} />
          <Route
            path="/iam/roles"
            element={
              <ProtectedRoute permissions={['spark.iam.get']}>
                {<Roles />}
              </ProtectedRoute>
            }
          />
          <Route path="/iam/roles/:id" element={<Role />} />
          <Route
            path="/iam/roles/:id/permissions"
            element={<RolePermissions />}
          />
          <Route
            path="/iam/users"
            element={
              <ProtectedRoute permissions={['spark.iam.get']}>
                {<Users />}
              </ProtectedRoute>
            }
          />
          <Route path="/iam/users/:staff_id/roles" element={<UserRoles />} />
          <Route
            path="/iam/user-profiles"
            element={
              <ProtectedRoute permissions={['spark.iam.get']}>
                {<UserProfiles />}
              </ProtectedRoute>
            }
          />
          <Route path="/iam/user-profiles/:id" element={<UserProfile />} />
          <Route
            path="/iam/user-profiles/:id/roles"
            element={<UserProfileRoles />}
          />
          <Route
            path="/dealer-network/inventory-push"
            element={
              <ProtectedRoute
                permissions={['spark.products.retail.inventory-push.update']}
              >
                {<BcInventoryPush />}
              </ProtectedRoute>
            }
          />
          <Route path="/customers/accounts" element={<Customers />} />
          <Route
            path="/inventory/warehouse-inventory"
            element={<WarehouseInventory />}
          />
          <Route
            path="/wms/inventory-taker"
            element={
              <ProtectedRoute permissions={[WMS_INVENTORY_TAKER_GET_PERM]}>
                {<WmsInventoryTaker />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/wms/inventory-taker/:physical_inventory_id"
            element={
              <ProtectedRoute permissions={[WMS_INVENTORY_TAKER_GET_PERM]}>
                {<WmsInventoryTakerLogs />}
              </ProtectedRoute>
            }
          />
          <Route path="/customers/tasks" element={<Tasks />} />
          <Route path="/customers/tasks/:id" element={<Task />} />
          <Route
            path="/customers/:customers_id/tasks"
            element={<CustomerTasks />}
          />
          <Route
            path="/customers/:customers_id/tasks/:id"
            element={<CustomerTask />}
          />
          <Route
            path="/customers/:customers_id/tasks-embedded"
            element={<TasksEmbedded />}
          />
          <Route
            path="/customers/:customers_id/tasks-embedded/:id"
            element={<TaskEmbedded />}
          />
          <Route path="/account/change-password" element={<ChangePassword />} />
          <Route path="/orders/:query" element={<Orders />} />
          <Route path="/orders/:query/:orders_id" element={<Order />} />
          <Route path="/settings/user-settings" element={<UserSettings />} />
          <Route
            path="/settings/staff-accounts/:staff_id"
            element={<StaffAccount />}
          />
          <Route path="/settings/staff-accounts" element={<StaffAccounts />} />
          {/* <Route
            path={'/settings/staff-accounts'}
            element={
              <ProtectedRoute permissions={['spark.settings.staff.get']}>
                {<StaffAccounts />}
              </ProtectedRoute>
            }
          /> */}
          <Route
            path={'/products/editor'}
            element={
              <ProtectedRoute permissions={[PRODUCT_EDITOR_GET_PERM]}>
                {<Products />}
              </ProtectedRoute>
            }
          />
          <Route
            path={'/products-pro-line'}
            element={
              <ProtectedRoute
                permissions={[PRODUCT_EDITOR_PRO_LINE_UPDATE_PERM]}
              >
                {<ProductsLegacy />}
              </ProtectedRoute>
            }
          />
          <Route
            path={'/products/editor/:products_id'}
            element={
              <ProtectedRoute permissions={[PRODUCT_EDITOR_GET_PERM]}>
                {<ProductEditorTabs />}
              </ProtectedRoute>
            }
          />
          <Route
            path={'/products/editor/:products_id/:tab'}
            element={
              <ProtectedRoute permissions={[PRODUCT_EDITOR_GET_PERM]}>
                {<ProductEditorTabs />}
              </ProtectedRoute>
            }
          />
          <Route
            path={'/products-pro-line/:products_id'}
            element={<OriginalProductEditor />}
          />
          <Route
            path={'/fulfillment/spirit-quote/months-settings'}
            element={<SpiritQuoteMonthsSettings />}
          />
          <Route
            path={'/fulfillment/spirit-quote/date-range'}
            element={<SpiritQuoteDateRange />}
          />
          <Route
            path={'/fulfillment/shipping'}
            element={
              <ProtectedRoute permissions={[SHIPPING_UPDATE_PERM]}>
                {<ShippingOrderList />}
              </ProtectedRoute>
            }
          />
          <Route
            path={'/fulfillment/shipping/:orders_id'}
            element={
              <ProtectedRoute permissions={[SHIPPING_UPDATE_PERM]}>
                {<ShippingOrderDetail />}
              </ProtectedRoute>
            }
          />
          <Route
            path={'/fulfillment/pickup-date-times/months-settings'}
            element={<PickupDateTimesSettings />}
          />
          <Route
            path={'/fulfillment/spirit-quote/months-settings/:year'}
            element={<SpiritQuoteMonthsSettings />}
          />
          <Route
            path={'/fulfillment/spirit-quote/month-settings/:id'}
            element={<SpiritQuoteMonthSettings />}
          />
          <Route
            path={'/fulfillment/spirit-quote/mileage-discounts'}
            element={<SpiritQuoteMileageDiscounts />}
          />
          <Route
            path={'/fulfillment/spirit-quote/pallet-discounts'}
            element={<SpiritQuotePalletDiscounts />}
          />
          // start
          <Route
            path={
              'accounting/product-pricing-projection-versions/:version_id/manufacturers/:price_manufacturers_id'
            }
            element={<PriceManufacturerDetail />}
          />
          <Route
            path={
              '/product-pricing-projection/price-inbound-freight/:versionId/:detailItemId'
            }
            element={<PriceInboundFreight />}
          />
          <Route
            path={
              '/accounting/product-pricing-projection-versions/:version_id/suppliers/:id'
            }
            element={<PriceSuppliers />}
          />
          <Route
            path={
              '/accounting/product-pricing-projection-versions/:version_id/product-multipliers/:price_product_multiplier_id'
            }
            element={<PriceProductMultiplierSettings />}
          />
          <Route
            path={
              'accounting/product-pricing-projection-versions/:version_id/forex/:price_forex_id'
            }
            element={<PriceForexDetail />}
          />
          <Route
            path={
              '/accounting/product-pricing-projection-versions/:version_id/price-inbound_template_items/list/'
            }
            element={<PriceInboundTemplateItems />}
          />
          <Route
            path={
              '/accounting/product-pricing-projection-versions/:version_id/price-inbound_template_items/detail/:container_id/:container_notes_count'
            }
            element={<PriceInboundTemplateItemsDetail />}
          />
        </Route>

        <Route path={'/warehouse/order-pallets'} element={<OrderPallets />} />

        <Route path="*" element={<Page404 />} />
      </ReactRoutes>
    </div>
  )
}

export default Routes
